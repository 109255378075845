<template>
  <el-dialog
    id="AttachFileDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="496px"
    close-on-press-escape
    class="my-dialog abow_dialog"
    @open="onBeforeShow"
    @opened="onShow"
    @close="onBeforeClose"
    destroy-on-close
    append-to-body
    @keydown.enter.native="onCommit"
    v-loading="loading"
  >
    <el-row v-if="!model.id">
      <el-upload
        ref="uploadInput"
        :action="uploadAction"
        :headers="uploadHeaders"
        class="upload-demo"
        list-type="text"
        :on-change="onFileSelect"
        :before-remove="onFileSelectRemove"
        :limit="1"
        :file-list="fileList"
        show-file-list
        accept=".doc,.docx,.pdf,.xls,.xlsx,.jpg,.jpeg,.png"
        :auto-upload="false"
        :on-error="handleUploadError"
        :on-success="handleUploadSuccess"
        :before-upload="handleUploadBefore"
        :on-progress="handleUploadProgress"
        ><el-button slot="trigger" size="small" type="primary">{{ $t("Оберіть файл") }}</el-button>
        <el-button style="margin-left: 16px;" size="small" type="success" @click="submitUpload" v-if="fileList.length">{{
          $t("Завантажити на сервер")
        }}</el-button>
        <div slot="tip" class="el-upload__tip">{{ $t("Підтримуються файли *.doc,*.docx,*.pdf,*.xls,*.xlsx,*.jpg,*.jpeg,*.png") }}</div>
      </el-upload>
    </el-row>
    <el-row v-if="model.id">
      <el-col :span="24"
        ><p>
          {{ $t("Назва") }}: {{ model.srcName }} <i class="el-icon-delete hover-icon" style="padding-left:8px" :title="$t('Видалити')" @click="onDelete"></i>
          <i class="el-icon-download hover-icon" style="padding-left:8px" :title="$t('Скачати')" @click="onDownload"></i>
        </p>
        <p>{{ $t("Розмір") }}: {{ sizeComp }}</p>
        <p v-if="model.uploaded">{{ $t("Завантажено") }}: {{ dateComp }}</p></el-col
      >
    </el-row>

    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Закрити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";

export default {
  name: "AttachFile",
  components: {},
  props: ["busEvent"],
  data() {
    var that = this;
    return {
      fileTypes: [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
        "application/xls",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "image/png",
        "image/jpeg",
      ],
      dialogShow: false,
      title: $tt("Планограма"),
      model: {},
      initModel: {},
      fileList: [],
      contractId: null,
      loading: false,
    };
  },

  methods: {
    onCommit() {
      this.$emit("attach-commit", this.model);
      this.dialogShow = false;
    },
    onBeforeShow() {},
    onShow() {
      this.model = _.extend({}, this.initModel);
    },
    onBeforeClose() {
      this.$store.dispatch("main/cancelAllRequests");
    },
    onFileSelect(file, fileList) {
      this.fileList = fileList.filter((f) => this.fileTypes.includes(f.raw.type));
    },
    onFileSelectRemove(file, fileList) {
      this.fileList = [];
      return true;
    },
    onDelete() {
      var that = this;
      this.$confirm($tt("Ви дійсно хочете видалити файл {name}?", { name: this.model.srcName }), $tt("Увага"), {
        confirmButtonText: $tt("Так"),
        cancelButtonText: $tt("Ні"),
        type: "warning",
      })
        .then(() => {
          that.model = {};
        })
        .catch((err) => {});
    },
    onDownload() {
      if (!this.contractId) {
        this.$message.error($tt("Помилка! Завантаження неможливе без збереження договору"));
      } else {
        this.loading = true;
        this.$store
          .dispatch("contracts/downloadAttachedFile", { contractId: this.contractId, fileId: this.model.id, fileName: this.model.srcName })
          .finally(() => {
            this.loading = false;
          })
          .then(() => {})
          .catch((err) => {
            globalErrorMessage($tt("Помилка"), err);
          });
      }
    },
    submitUpload() {
      this.$refs.uploadInput.submit();
    },
    handleUploadSuccess(data) {
      this.$refs.uploadInput.clearFiles();
      if (data && data.success) {
        this.$message.success($tt("Файл завантажено [{id}]!", { id: data.data.id }));
        this.model = data.data;
      } else {
        this.$message.error($tt("Помилка!") + data.msg);
      }
    },
    handleUploadBefore(file) {
      const isLessThan100M = file.size <= 100 * 1024 * 1024; // 100M
      if (!isLessThan100M) {
        this.$message.error($tt("Розмір файлу має бути меньшим за 100 Mб!"));
      }
      return isLessThan100M;
    },
    handleUploadProgress(data) {},
    handleUploadError(data) {
      this.$refs.uploadInput.clearFiles();
      this.$message.error($tt("Помилка!") + JSON.parse(data) ? JSON.parse(data).msg : data);
    },
  },
  computed: {
    uploadAction() {
      return axios.defaults.baseURL + "/api/contract/upload/file";
    },
    uploadHeaders() {
      return {
        token: this.$store.getters["main/getToken"](),
      };
    },
    sizeComp() {
      return this.bytesToSize(this.model.size);
    },
    dateComp() {
      return new Date(this.model.uploaded).toLocaleDateString() + " " + new Date(this.model.uploaded).toLocaleTimeString();
    },
  },
  created() {
    bus.$on(this.busEvent + ".show", (data) => {
      this.title = (data && data.title) || this.title;
      this.initModel = _.extend({}, data ? data.initModel.attach : {});
      this.contractId = data && data.initModel ? data.initModel.contractId : null;
      this.dialogShow = true;
    });
    bus.$on(this.busEvent + ".hide", () => {
      this.dialogShow = false;
    });
  },
};
</script>
<style lang="scss">
#AttachFileDialog {
  .el-dialog {
    max-height: 265px !important;
  }
  .button-in-form {
    margin-bottom: 16px;
  }
  .el-row {
    margin-bottom: 20px;
  }
  i.hover-icon {
    padding-left: 8px;
  }
  i.hover-icon:hover {
    color: red !important;
  }
}
</style>
